import React from 'react'
import { usePrivateRoute } from 'src/utils/auth'
import { Router, RouteComponentProps } from '@reach/router'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import AddSuspensionPoint from 'src/components/add-suspension-point/add-suspension-point'

const AddViolationPage = () => {
  usePrivateRoute()
  return (
    <Router basepath="/players/:id/suspensions">
      <AddViolationRoute path="/add-violation" />
    </Router>
  )
}

interface AddViolationRouteProps extends RouteComponentProps {
  id?: string
}

const AddViolationRoute: React.FC<AddViolationRouteProps> = ({ id }) => {
  return (
    <Layout>
      <SEO title="Add violation" />
      <AddSuspensionPoint />
    </Layout>
  )
}

export default AddViolationPage
